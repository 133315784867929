import React, { useContext, useState } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import SimpleReactLightbox from 'simple-react-lightbox';
import styled from 'styled-components';

import { Feature } from '~/components/feature';
import { Gallery } from '~/components/gallery';
import { LightBox } from '~/components/lightbox';
import { PageHeading } from '~/components/page-heading';
import {
  getMappedAllProjects,
  getMappedServiceProjects,
} from '~/core/data-mappers';
import { AppContext } from '~/layout/context';
import { mediaQuery } from '~/theme';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';

const ServiceSection = styled(Feature)`
  padding: 0;
`;

const ServiceWrapper = styled.div`
  padding-bottom: ${rem(128)};

  ${mediaQuery('xl')} {
    padding-bottom: ${rem(96)};
  }

  ${mediaQuery('md')} {
    padding-bottom: ${rem(64)};
  }

  ${mediaQuery('sm')} {
    padding-bottom: ${rem(48)};
  }
`;

const ServicesTemplate = ({
  data: {
    pageData: {
      HomeFields: { servicesPage: pageData },
    },
    seoData,
    allWpService: { services },
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const { setRequestModal } = useContext(AppContext);
  const { t } = useTranslation();

  const allProjects = getMappedAllProjects(services);

  let projectsRenderCount = 0;

  return (
    <SimpleReactLightbox>
      <Seo post={seoData} />

      <PageHeading
        description={pageData.description}
        title={pageData.title}
        headingTag="h1"
      />

      {services.map(({ serviceFields: service }, index) => {
        const projects = getMappedServiceProjects(service);
        const renderCount = projectsRenderCount;
        projectsRenderCount += projects.length;

        return (
          <ServiceWrapper key={service.title}>
            <ServiceSection
              id={`${t('services.service')}-${index + 1}`}
              image={getImage(service.image?.localFile?.childImageSharp)}
              imageAlt={service.image.altText}
              title={service.title}
              description={service.description}
              buttonText={t('services.offer')}
              buttonOnClick={() => setRequestModal(true)}
              isReverse={Boolean(index % 2)}
              icon={{
                src: service.icon.localFile.publicURL,
                alt: service.icon.altText,
              }}
            />
            <Gallery
              title={t('services.projects')}
              items={projects}
              onClick={setActiveIndex}
              renderCount={renderCount}
              limit={4}
            />
          </ServiceWrapper>
        );
      })}

      <LightBox
        images={allProjects.map(cerf => cerf.image)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </SimpleReactLightbox>
  );
};

export const query = graphql`
  query($language: String, $locale: String) {
    allWpService(filter: { locale: { locale: { eq: $locale } } }) {
      services: nodes {
        serviceFields {
          description
          title
          icon {
            altText
            localFile {
              publicURL
            }
          }
          projects {
            title
            description
            image {
              localFile {
                thumbnail: childImageSharp {
                  gatsbyImageData(width: 640, placeholder: BLURRED)
                }
                full: childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                placeholder: childImageSharp {
                  gatsbyImageData(height: 4, placeholder: BLURRED)
                }
              }
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 1024, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    seoData: wpPage(
      slug: { eq: "services" }
      locale: { locale: { eq: $locale } }
    ) {
      ...YoastSiteFragment
    }
    pageData: wpPage(
      slug: { eq: "home" }
      locale: { locale: { eq: $locale } }
    ) {
      HomeFields {
        servicesPage: services {
          title
          description
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default ServicesTemplate;
