import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { rem } from 'polished';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Card } from '~/components/card';
import { Subheading } from '~/components/subheading';
import { Container } from '~/theme';

const GalleryWrapper = styled(Container)`
  margin-top: ${rem(48)};

  > * {
    padding: 0;
  }
`;

const GalleryList = styled(Row)`
  margin-top: ${rem(12)};
`;

export const Gallery = ({ title, items, onClick, renderCount = 0, limit }) => {
  return (
    <GalleryWrapper>
      <FadeIn>
        <Subheading>{title}</Subheading>
      </FadeIn>

      <GalleryList>
        {items.map(
          (item, index) =>
            limit > index && (
              <Col lg={3} md={4} xs={6} key={item.title}>
                <FadeIn delay={(index + 1) * 0.15}>
                  <Card
                    isSmall
                    title={item.title}
                    image={item.image}
                    onClick={() => onClick(renderCount + index)}
                  />
                </FadeIn>
              </Col>
            )
        )}
      </GalleryList>
    </GalleryWrapper>
  );
};
